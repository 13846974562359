import React from "react";

 export default function  OrgChart  () {
    return(
    <>
    <div className="flex flex-col justify-center items-center">
    <img src='../assets/images/FY2024OrgChart.jpg' alt="FY2022OrgChart" className="flex w-full h-full rounded-3xl object-scale-down" />
    </div>
</>
    )
}