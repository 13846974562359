/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';

import heroPortfolio from '../assets/images/recruit.jpeg';

export default function HeroRecruit() {
  return (
    <section className="hero sm:items-center lg:items-start sm:flex-row">
      <Fade bottom>
        <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
          <h1 className="text-xl xl:text-6xl text-theme-blue font-bold leading-tight mb-5">
            Recruit
          </h1>
          <p className="font-light text-sm xl:text-xl text-theme-blue leading-relaxed text-justify">
          DataOneTechは成長するフィールドを提供し続け社員はやりがいをもって仕事に取り組む
          <br />あなたの挑戦心を受け入れる環境がここにある！
          </p>
        </div>
        <div className="w-full sm:w-1/2 sm:pr-12">
          <img src={heroPortfolio} alt="Hero" />
        </div>
      </Fade>
    </section>
  );
}
