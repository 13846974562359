import React from 'react';
import { Fade } from 'react-reveal';

const Feature = ({ title, text }) => (
    <Fade bottom>
    <div className=" justify-start xl:mb-1">
      <h1 className='text-start text-theme-blue text-sm xl:text-lg underline'>{title}</h1>
      <p className='font-light text-theme-blue text-start mb-3 whitespace-pre-wrap text-sm xl:text-lg'>{text}</p>
    </div>
    </Fade>
);

export default Feature;
