import React from "react";
import Fade from 'react-reveal/Fade';

const infoSecData = [
    {
        icData: '株式会社データワンテクノロジー（以下「当社」）は、 「ITを通じた様々なサービス提供により 社員・お客さま・社会に「安心・感動」をもたらす」を経営理念として活動しています。',
    },
    {
        icData: '経営理念実現には、情報セキュリティに関しても高い企業モラルを堅持し、 お客様のシステムやデータ等の情報資産ならびに会社の経営資源としての情報資産を さまざま脅威から保護することが重要な課題となります。',
    },
    {
        icData: 'ここに情報資産の適切な保護を徹底するために情報セキュリティ方針を策定し、 今後はこの「情報セキュリティ方針」および別掲の 「個人情報保護方針」 を順守し、 あらゆる脅威から情報資産を保護し、かつ適正に取り扱うことにより、情報セキュリティの維持に努めます。 ',
    }
]

const infoSecOpData = [
    {
        title:'１．情報セキュリティ管理体制について',
        icOpData: '情報セキュリティ担当役員（CISO）を設置し、その責任のもと「情報セキュリティ委員会」を中心とする情報セキュリティ管理体制を組織し、 各事業を統括するリスクオーナーの責任と権限に基づき、 情報セキュリティマネジメントシステム（ISMS）を推進します。これにより全社にわたる情報セキュリティの状況を正確に把握し、必要な対策を迅速に実施できるよう積極的な活動を行います。 ',
    },
    {
        title: '２．情報セキュリティに関する内部規程の整備',
        icOpData: '情報セキュリティ方針に基づいた内部規程を整備し、個人情報だけではなく、情報資産全般の取り扱いについて明確な方針を示すとともに、情報漏えい等に対しては、厳しい態度で臨むことを社内外に周知徹底します。',
    },
    {
        title: '３．情報セキュリティリテラシーの向上',
        icOpData: '従業員等にセキュリティ教育・訓練を徹底し、当社の情報資産に関わる全員が、 情報セキュリティリテラシーを持って業務を遂行できるようにします。 また、刻々と変わる状況に対応できるよう、教育・訓練を継続して行っていきます。',
    },
    {
        title: '４．継続的改善',
        icOpData: 'ISMSを運用するとともに継続的な改善を図り、お客様をはじめとする社内外のステークホルダーの期待に応えていきます。',
    }   ,
    {
        title: '情報セキュリティ方針の対象',
        icOpData: '当方針が対象とする「情報資産」とは、当社の企業活動において入手および知り得た情報ならびに当社が業務上保有する全ての情報とし、この情報資産の取り扱いおよび管理に携わる当社の「役員、社員、派遣社員等」および当社の情報資産を取り扱う「業務委託先およびその従業員」が順守することとします。',
    },
    {
        title: '附則',
        icOpData: 'この情報セキュリティ方針は、2017年9月8日から施行します。',
    }

]

const InfoSec = () => {
    return(
        <>
        <Fade bottom>
        <div className="w-full  flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
          <h1 className="text-xl text-theme-blue  text-justify font-bold leading-tight mb-5">
            情報セキュリティ方針
          </h1>
      
          {infoSecData.map((item,index) => (
            <Fade bottom delay={200 * index}>
                <p className="font-light mb-5 text-justify  text-theme-blue leading-relaxed tracking-wider indent-8 xl:text-lg sm:text-xs">{item.icData}</p>
            </Fade>

          ))}
           <h1 className="my-4 text-xl text-theme-blue text-justify font-bold leading-tight mb-5">
           情報セキュリティ方針の運用
          </h1>
      
          {infoSecOpData.map((item,index) => (
            <Fade bottom delay={200 * index}>
                <h2 className="text-lg text-theme-blue text-justify font-semibold leading-tight mb-5">{item.title}</h2>
                <p className="font-light mb-5 text-justify text-theme-blue leading-relaxed tracking-wider indent-8 xl:text-lg sm:text-xs ">{item.icOpData}</p>
            </Fade>

          ))}
          
          </div>
      </Fade>
        </>
    );
}

export default InfoSec;