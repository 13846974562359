/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from "react";

import Fade from "react-reveal/Fade";

import NotFound from "../assets/images/NotFound.png";
import Button from "../elements/Button";
import FeaturePage from "../pages/FeaturePage";
import History from "./History";
import InfoSec from "./InfoSec";
import OrgChart from "./OrgChart";
import PrivacyPolicy from "./PrivacyPolicy";
import Vision from "./Vision";

const pageComponents = {
  FeaturePage : FeaturePage,
  InfoSec : InfoSec,
  PrivacyPolicy : PrivacyPolicy,
  OrgChart: OrgChart,
  Vision:Vision,
};

let Page;

export default function AboutUsDetail({ data }) {
  if (data === null) {
    return (
      <section className="container mx-auto">
        <Fade bottom>
          <div className="flex flex-col w-full justify-center">
            <div className="flex w-full justify-center">
              <img
                src={NotFound}
                alt="Not Found"
                className="sm:w-3/4 xl:w-5/12 mt-5"
              />
            </div>
            <h1 className="text-theme-blue text-2xl text-center mt-5">
              Aboutus Not Found
            </h1>
            <div className="flex justify-center">
              <Button
                href="/aboutus"
                type="link"
                className="flex w-30 h-10 px-5 mt-5 bg-theme-blue text-white items-center rounded transform transition duration-500 hover:bg-gray-900"
              >
                Go Back
              </Button>
            </div>
          </div>
        </Fade>
      </section>
    );
  }

  return (
    <section className="container mx-auto">
      <Fade bottom>
        <Button
          type="link"
          href="/aboutus"
          className="flex w-40 h-8 text-lg items-center ml-6 sm:ml-20 mt-8 font-light text-gray-400 hover:underline"
        >
          <svg
            className="w-5 h-5 text-gray-400 mr-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          See All aboutus
        </Button>
      </Fade>

      {data.map((item) => (
        <div className="flex flex-col mt-8 justify-center">
          <Fade bottom>
            <h1 className="xl:text-2xl text-sm text-theme-blue text-center font-bold">
              {item.title}
            </h1>
            <p className="text-sm xl:text-lg text-center text-theme-blue my-2">
              {item.details}
            </p>

          </Fade>
          <Fade bottom delay={300 * 1}>
            <div className="flex mx-auto flex-col mt-6 mb-2 mx-4 sm:mx-6 xl:mx-8">
            {
              !function(){
                Page = pageComponents[item.page]
              } ()
             }
             <Page />
             {
              item.page === 'FeaturePage' ? 
              <History />:null
             }

            </div>
          </Fade>
        </div>
      ))}
      <Fade bottom>
        <Button
          type="link"
          href="/aboutus"
          className="flex w-40 h-8 text-lg items-center ml-6 sm:ml-20 mt-8 font-light text-gray-400 hover:underline"
        >
          <svg
            className="w-5 h-5 text-gray-400 mr-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          See All aboutus
        </Button>
      </Fade>
    </section>
  );
}
