/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from "react";
import Fade from "react-reveal/Fade";

import NotFound from "../assets/images/NotFound.png";
import Button from "../elements/Button";

export default function RecruitDetail({ data }) {
  if (data === null) {
    return (
      <section className="container mx-auto">
        <Fade bottom>
          <div className="flex flex-col w-full justify-center">
            <div className="flex w-full justify-center">
              <img
                src={NotFound}
                alt="Not Found"
                className="sm sm:text-xs:w-3/2 xl:w-5/12 mt-5"
              />
            </div>
            <h1 className="text-theme-blue text-2xl text-center mt-5">
              Recruit Not Found
            </h1>
            <div className="flex justify-center">
              <Button
                href="/project"
                type="link"
                className="flex w-30 h-10 px-5 mt-5 bg-theme-blue text-white items-center rounded transform transition duration-500 hover:bg-gray-900"
              >
                Go Back
              </Button>
            </div>
          </div>
        </Fade>
      </section>
    );
  }

  return (
    <section className="container mx-auto">
      <Fade bottom>
        <Button
          type="link"
          href="/recruit"
          className="flex w-40 h-8 text-sm sm:text-xs xl:text-lg items-center ml-6 sm sm:text-xs:ml-20 mt-8 font-light text-theme-blue hover:underline"
        >
          <svg
            className="w-5 h-5 text-theme-blue mr-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          See All Recruit
        </Button>
      </Fade>

      {data.map((item) => (
        <div className="flex flex-col mt-8 justify-center">
          <Fade bottom>
            <h1 className="text-xl xl:text-5xl text-theme-blue text-center font-bold">
              {item.title}
            </h1>
          </Fade>

          <Fade bottom delay={300 * 1}>
            <div className="flex justify-center xl:mb-6">
              <img
                src={`../${item.imageUrl}`}
                alt="Project"
                className="flex w-2/5 sm sm:text-xs:w-2/6"
              />
            </div>
          </Fade>

          <Fade bottom delay={300 * 1}>
            <div className="flex flex-col mt-16 mb-12 mx-8 sm:text-xs mx-16 xl:mx-28">
              <h1 className="text-sm sm:text-xs xl:text-3xl text-theme-blue font-bold mb-3">
                時代とともに、進化し続けるエンジニアになろう！
              </h1>
              <h2 className="text-sm sm:text-xs xl:text-xl text-theme-blue font-bold mb-3">
                ご応募方法：１．当社人事部メールアドレス（recruit@i-dataone.co.jp）へ直接ご連絡いただけます。
                <br />
                　２．「Contact
                Us」フォームにてご記入頂ければ弊社人事部担当者よりご連絡いたします。
              </h2>
              <table class="table-auto">
                <tbody>
                  <tr>
                    <td class="border px-2 py-2 font-semibold text-sm sm:text-xs xl:text-lg text-theme-blue text-justify whitespace-pre-wrap tracking-wider leading-10">
                      職務内容
                    </td>
                    <td class="border px-2 py-2 text-sm sm:text-xs xl:text-lg text-theme-blus text-justify whitespace-pre-wrap tracking-wider leading-10">
                      {item.duties}
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-2 py-2 font-semibold text-sm sm:text-xs xl:text-lg text-theme-blue text-justify whitespace-pre-wrap tracking-wider leading-10">
                      案件事例
                    </td>
                    <td class="border px-2 py-2 text-sm sm:text-xs xl:text-lg text-theme-blus text-justify whitespace-pre-wrap tracking-wider leading-10">
                      {item.projectCase}
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-2 py-2 font-semibold text-sm sm:text-xs xl:text-lg text-theme-blue text-justify whitespace-pre-wrap tracking-wider leading-10">
                      職務の魅力
                    </td>
                    <td class="border px-2 py-2 text-sm sm:text-xs xl:text-lg text-theme-blus text-justify whitespace-pre-wrap tracking-wider leading-10">
                      {item.jobCharm}
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-2 py-2 font-semibold text-sm sm:text-xs xl:text-lg text-theme-blue text-justify whitespace-pre-wrap tracking-wider leading-10">
                      必須条件
                    </td>
                    <td class="border px-2 py-2 text-sm sm:text-xs xl:text-lg text-theme-blus text-justify whitespace-pre-wrap tracking-wider leading-10">
                      {item.mustSkill}
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-2 py-2 font-semibold text-sm sm:text-xs xl:text-lg text-theme-blue text-justify whitespace-pre-wrap tracking-wider leading-10">
                      希望条件
                    </td>
                    <td class="border px-2 py-2 text-sm sm:text-xs xl:text-lg text-theme-blus text-justify whitespace-pre-wrap tracking-wider leading-10">
                      {item.betterSkill}
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-2 py-2 font-semibold text-sm sm:text-xs xl:text-lg text-theme-blue text-justify whitespace-pre-wrap tracking-wider leading-10">
                      雇用形態
                    </td>
                    <td class="border px-2 py-2 text-sm sm:text-xs xl:text-lg text-theme-blus text-justify whitespace-pre-wrap tracking-wider leading-10">
                      {item.employeStatus}
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-2 py-2 font-semibold text-sm sm:text-xs xl:text-lg text-theme-blue text-justify whitespace-pre-wrap tracking-wider leading-10">
                      年収
                    </td>
                    <td class="border px-2 py-2 text-sm sm:text-xs xl:text-lg text-theme-blus text-justify whitespace-pre-wrap tracking-wider leading-10">
                      {item.incom}
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-2 py-2 font-semibold text-sm sm:text-xs xl:text-lg text-theme-blue text-justify whitespace-pre-wrap tracking-wider leading-10">
                      選考フロー
                    </td>
                    <td class="border px-2 py-2 text-sm sm:text-xs xl:text-lg text-theme-blus text-justify whitespace-pre-wrap tracking-wider leading-10">
                      {item.flow}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Fade>
        </div>
      ))}
      <Fade bottom>
        <Button
          type="link"
          href="/recruit"
          className="flex w-40 h-8 text-sm sm:text-xs xl:text-lg items-center ml-6 sm sm:text-xs:ml-20 mt-8 font-light text-theme-blue hover:underline"
        >
          <svg
            className="w-5 h-5 text-theme-blue mr-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          See All Recruit
        </Button>
      </Fade>
    </section>
  );
}
