/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';

import Header from '../parts/Header';
import Footer from '../parts/Footer';

import Data from '../json/aboutUsPage.json';
import { useParams } from 'react-router-dom';
import AboutUsDetail from '../parts/AboutUsDetail';

export default function AboutUsDetailPage ( {props} ) {

  useEffect( () => {
    window.scrollTo(0,0);
  },[]);
  
    let { aboutUsId } = useParams();
 
    const detailData = Data.aboutUs.filter((item) => item.id === `${aboutUsId}`);

    return (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Header {...props} />
        <AboutUsDetail data={detailData.length === 1 ? [detailData[0]] : null} />
        <Footer />
      </>
    );
  
}
