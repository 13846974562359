import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const jobSchema = {
  "@context": "http://schema.org",
  "@type": "JobPosting",

  title: "システム開発エンジニア",
  hiringOrganization: {
    "@type": "Organization",
    name: "株式会社データワンテクノロジー",
  },
  description:
    "DataOneTechは成長するフィールドを提供し続け社員はやりがいをもって仕事に取り組む、あなたの挑戦心を受け入れる環境がここにある！",
  baseSalary: "300000",
  jobBenefits:
    " 年俸制、社会保険完備、通勤手当（実費支給）、教育・資格取得支援金、お誕生日クオカード、決算賞与（サプライズボーナス）",
  salaryCurrency: "JPY",
  workHours:
    "9:00〜18:00 ※プロジェクトによって、フレックスタイム制（標準労働時間1日8時間）あり",
  datePosted: "2022-09-29",
  validThrough: "2023-12-31",
  educationRequirements: "大学卒",
  employmentType: "フルタイム",
  experienceRequirements:
    "javaを中心とした、オブジェクト指向言語での開発経験（java、JavaScript、C、C++、VB、PHP、Perl、Delphi 、C#、.NET)2年以上の方",
  jobLocation: {
    "@type": "Place",
    address: {
      "@type": "PostalAddress",
      addressLocality: "台東区",
      addressRegion: "東京都",
      addressCountry: "JP",
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(jobSchema)}
          </script>
        </Helmet>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
