/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import BrandIcon from '../parts/BrandIcon';

export default function Footer() {
  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col xl:flex-row mt-8 justify-center">
          <div className="w-full xl:w-1/3 flex-col">
            <BrandIcon />
            <p className="text-sm xl:text-lg text-theme-blue font-light">
              確かな価値をご提供し続ける
              {' '}
              <br />
              ITプロフェッショナル集団です。
            </p>
          </div>
          <div className="w-full xl:w-1/3 flex-col">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
              Office
            </h1>
            <p className="text-sm xl:text-bese text-theme-blue font-light">
            〒111-0053 東京都台東区浅草橋4-10-8 TFAビル7F<br />
            JR線 浅草橋駅西口より徒歩2分
            </p>
            <p className="text-sm xl:text-bese text-theme-blue font-light">
            TEL: 03-5829-4360 FAX: 03-5539-4819
            </p>
            <p className="text-base text-theme-blue font-light">
              info@i-dataone.co.jp
            </p>
          </div>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-sm xl:text-lg text-theme-blue font-light">
            Copyright All rights reserved - Corporation DataOneTech
          </p>
          <div className="flex-row">
            <p className="inline-block text-sm xl:text-lg text-theme-blue font-light">
              Made with  by&nbsp; Our Team
            </p>
            
          </div>
        </div>
      </div>
    </div>
  );
}
