/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

import Header from '../parts/Header';
import Footer from '../parts/Footer';
import Map from '../parts/Map';



export default class AccessPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <Map {...this.props} />
        <Footer />
      </>
    );
  }
}
