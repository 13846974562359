import React from "react";
import Fade from 'react-reveal/Fade';

const infoSecOpData = [
    {
        title:'企業理念',
        icOpData: '「誠実さ」最高水準の誠実さと公平さをもちあらゆる事業を遂行す る。私たちは信頼できる会社です。 \n\n　　「最善の努力」誰もが引き受けた仕事で最善を尽くさなければならないです。Bプラスではなく、Aを目指します。 \n\n　　「チームワーク」「私」ではなく「私たち」を主語に考えます。 \n\n　　「楽しさ」仕事は楽しく、本質的に満足できるものであるべき、 私たちが楽しんでいなければ、どこか間違っている。それを見つけ改善します。 \n\n　　「常に進み続ける」自発的に変化、改善、学習、基準の向上を続け ることそのものに価値があります。 ',
    },
    {
        title: 'パーパス',
        icOpData: '私たちは社会・顧客のIT課題解決に最高なソリューション・サービスを提供し続けるため存在します。 ',
    },
    {
        title: 'ミッション',
        icOpData: '2030年までに日本IT業界でもっとも信頼・敬愛される会社の一つになることを目指します。 ',
    }   ,
    {
        title: '企業文化',
        icOpData: '１．鏡と窓\n　  ２．枠組みのなかの自由と規律\n　  ３．厳しい現実を直視した上で勝利への確信',
    },
    {
        title: '社員信条',
        icOpData: '１．わたしたちは、「お客様のため」最善を尽くします\n　  ２．わたしたちは、「行動・挑戦」し続けます。\n　  ３．わたしたちは、より「やりがい・生きがい」のある「明るい会社」をつくります',
    },
    {
        title: '行動規範',
        icOpData: '・倫理的で責任ある事業活動：適用法令を遵守し、いかなる形態の企業犯罪や違法行為も一切容認しません。\n　  ・人権の尊重：すべての人々と国が達成すべき共通の基準として採択された「世界人権宣言」をはじめ、グローバルな視点で議論・採択された国際規約・条約を遵守します。\n　  ・ダイバーシティ：私たちは、多様な個性、背景、考え方や価値観を持ったあらゆる人々（お客様、ビジネスパートナーおよび社員など）を尊重します。また、社員一人ひとりが持つ才能を活かし、知恵を出し合い、あらゆる人々と協力し合いながら活き活きと活躍する公平な社会の実現に取り組みます。\n　  ・機会均等：私たちは、多様な価値観を持つ人財がその能力を最大限に発揮し、活躍し続ける、明るく風通しの良い就業環境の実現に積極的に取り組みます。\n　  ・ハラスメント禁止：私たちは、ハラスメントのない安全かつ生産的でプロフェッショナルな就業環境の維持に努めるとともに、社員、お客様またはビジネスパートナーの皆様に雇用される社員を含むいかなる人に対しても嫌がらせやハラスメント行為を行いません。',
    }

]

const InfoSec = () => {
    return(
        <>
        <Fade bottom>
        <div className="w-full  flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-1 lg:mt-2 xl:mt-3">
          {infoSecOpData.map((item,index) => (
            <Fade bottom delay={200 * index}>
                <h2 className="text-lg text-theme-blue text-justify font-semibold leading-tight mb-5">{item.title}</h2>
                <p className="font-light mb-5 text-justify text-theme-blue leading-relaxed tracking-wider indent-8 xl:text-lg sm:text-xs whitespace-pre-wrap leading-loose">{item.icOpData}</p>
            </Fade>

          ))}
          
          </div>
      </Fade>
        </>
    );
}

export default InfoSec;