import { Component } from "react";
import Header from "../parts/Header";
import Service from "../parts/Service";

import Data from "../json/landingPage.json"
import DataAboutUs from "../json/aboutUsPage.json"
import Portfolio from "../parts/Portfolio";
import Advantage from "../parts/Advantage";
import Testimonial from "../parts/Testimonial";
import Discuss from "../parts/Discuss";
import Footer from "../parts/Footer";
import HeroSection from "./HeroSection";
import AboutUs from "../parts/AboutUs";


export default class LaningPage extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <>
                <Header {...this.props} />
                <HeroSection />
                <Service data={Data.service} />
                <Portfolio data={Data.portfolio} />
                <AboutUs data={DataAboutUs.aboutUs} />
                <Advantage data={Data.advantage} />
                <Testimonial data={Data.testimonial} />
                <Discuss />
                <Footer />
            </>
        );
    }

}