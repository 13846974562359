/* eslint-disable no-useless-escape */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';

import Fade from 'react-reveal/Fade';
import * as emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Form from '../elements/Form';
import Button from '../elements/Button';

export default function DiscussForm(props) {
  const { data, resetForm } = props;

  const submitEmail = () => {
    const {
      name, company, email, phone, projectIdea,
    } = data;

    const templateParams = {
      from_name: `${name} - ${company} ( ${phone} - ${email} )`,
      to_name: '営業・人事担当者',
      message: projectIdea,
    };

    if (
      name !== ''
            && company !== ''
            && email !== ''
            && phone !== ''
            && projectIdea !== ''
    ) {
      emailjs.send(
        'service_cpqhcul',
        'template_cj0r4ja',
        templateParams,
        'EIyYoEoa5i6rzb10t',
      )
        .then(() => {
          toast.success('Success! お問い合わせありがとうございます。数営業日以内に返事いたします。');
          resetForm();
        }, (error) => {
          toast.error(error);
        });
    } else {
      toast.error('未記入の項目があります。');
    }
  };

  return (
    <section className="flex flex-col container mx-auto mt-10 justify-center">
      <Fade bottom>
        <h1 className="text-sm xl:text-5xl text-theme-blue text-center font-bold">何でもお気軽にお問合せください。</h1>

        <p className="font-light text-sm xl:text-lg text-theme-blue text-justify xl:my-12 xl:mx-60 leading-10">          
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          弊社はお客様のご要望に対応できる優秀な人材を数多く抱えております。<br />
          また、強固な関係を築いているお客様より優良な案件を多数頂いております。<br />
          人材、案件のやり取りを通じて信頼関係を築かせて頂きたく思っております。<br />
          ご興味がありましたら、下記のフォームにお問合せ内容をご記入ください。数営業日以内にご返事いたします。
        </p>

        <div className="flex flex-col">
          <div className="flex flex-col sm:flex-row mx-auto">
            <Form
              id="name"
              name="name"
              type="text"
              value={data.name}
              placeholder="お名前"
              className="text-sm xl:text-lg"
              onChange={props.onChange}
            />
            <Form
              id="company"
              name="company"
              type="text"
              value={data.company}
              placeholder="貴社名"
              className="text-sm xl:text-lg"
              onChange={props.onChange}
            />
          </div>

          <div className="flex flex-col sm:flex-row mx-auto">
            <Form
              id="email"
              name="email"
              type="email"
              value={data.email}
              placeholder="メールアドレス"
              className="text-sm xl:text-lg"
              onChange={props.onChange}
            />
            <Form
              id="phone"
              name="phone"
              type="tel"
              value={data.phone}
              placeholder="ご連絡先"
              className="text-sm xl:text-lg"
              onChange={props.onChange}
            />
          </div>

          <div className="mx-auto">
            <Form
              id="projectIdea"
              name="projectIdea"
              type="textarea"
              value={data.projectIdea}
              placeholder="お問合せ内容"
              className="text-sm xl:text-lg"
              onChange={props.onChange}
            />
          </div>

          <Button
            className="text-sm xl:text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
            type="button"
            onClick={submitEmail}
          >
            Submit
          </Button>
        </div>
      </Fade>

      <ToastContainer />

    </section>
  );
}
