import './App.css';
import {  Route, Routes } from 'react-router-dom';
import LaningPage from './pages/LandingPage';

import './assets/css/styles.css';
import ProjectPage from './pages/ProjectPage';
import ProjectDetailPage from './pages/ProjectDetailPage';
import NotFoundPage from './pages/NotFoundPage';
import TeamPage from './pages/TeamPages';
import DiscussProjectPage from './pages/DiscussProjectPage';
import AboutUsPage from './pages/AboutUsPage';
import AboutUsDetailPage from './pages/AboutUsDetailPage';
import Recruit from './pages/Recruit';
import RecruitDetailPage from './pages/RecruitDetailPage';
import AccessPage from './pages/AccessPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route index path='/' element={<LaningPage />}></Route>
        <Route  path="/project" element={<ProjectPage />} />
        <Route path="/project/:projectId/" element={<ProjectDetailPage />} />
        <Route  path="/team" element={<TeamPage /> } />
        <Route  path="/aboutus" element={<AboutUsPage />} />
        <Route path="/aboutus/:aboutUsId/" element={<AboutUsDetailPage />} />
        <Route  path="/recruit" element={<Recruit />} />
        <Route path="/recruit/:recruitId/" element={<RecruitDetailPage />} />
        <Route  path="/access" element={<AccessPage />} />
        <Route  path="/contactus" element={<DiscussProjectPage />} />
        <Route path="" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
