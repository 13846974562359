/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

import Header from '../parts/Header';
import Discuss from '../parts/Discuss';
import Footer from '../parts/Footer';

import Data from '../json/aboutUsPage.json';
import HeroAboutUs from '../parts/HeroAboutUs';
import AllAboutUs from '../parts/AllAboutUs';

export default class AboutUsPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <HeroAboutUs {...this.props} />
        <AllAboutUs data={Data.aboutUs} />
        <Discuss {...this.props} />
        <Footer />
      </>
    );
  }
}
