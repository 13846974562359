import React from "react";
import { Zoom } from "react-reveal";
import Button from "../elements/Button";

function HeroSection() {
    return (
    <section className="heroSec">
      <div className='flex flex-col justify-center justify-items-center object-contain shadow-xl w-full '>
        <video playsInline autoPlay loop muted src='../assets/videos/background3.mov'  className="object-cover -z-10" />
        <Zoom right cascade >
        <h1 className="xl:-mt-160 lg:-mt-112 md:-mt-80 -mt-32 text-white sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold">DataOne Tech</h1>
        <p className="mt-2 sm:mt-2 xl:mt-4 text-white xl:text-2xl lg:text-xl text-sm font-bold">ITを通じて顧客・社員・ビジネスパートナー・社会に真の価値を提供し続けるプロフェッショナル集団です。</p>
        </Zoom>
        <div className="ml-4 xl:ml-8 w-full lg:w-1/2 xl:pl-12 sm:pr-2 xl:mt-24 mt-12">
        <Button href="/project" type="link" className="flex sm:w-30 w-71 h-18 items-center px-7 py-5 text-white text-sm xl:text-xl bg-theme-purple rounded-lg shadow-2xl hover:bg-dark-theme-purple transition duration-200">
            See Our Work
            <svg className="ml-2 w-7 h-7 text-white animate-bounce-x" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </Button>
        </div>
      </div>
    </section>
    );
  }
  
  export default HeroSection;