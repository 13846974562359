import React from 'react';
import { Fade } from 'react-reveal';


const brandData = [
  {
    company: '株式会社NTTデータ',
  },
  {
    company: '株式会社NTTデータ中国',
  },
  {
    company: '株式会社ゼンリンデータコム',
  },
  {
    company: '株式会社Ｂｌｕｅｓｈｉｐ',
  },
  {
    company: '日本電子計算株式会社',
  },
  {
    company: 'エクシオグループ株式会社',
  },
  {
    company: 'TDCソフト株式会社',
  },
  {
    company: '日本ユニカ・システムズ株式会社',
  },
  {
    company: '他多数',
  },
]

const Brand = () => (
  <Fade bottom>
    <p className='text-lg text-justify text-theme-blue my-2'>主要お取引先（敬称略）</p>
    {brandData.map((item,index) => (
      <Fade bottom delay={200 * index}>
        <div className='mb-5 box-border h-15 w-85 p-4  rounded-2xl shadow-xl border border-light-theme-purple transform transition duration-500 hover:scale-105'>
          <p className='text-justify xl:text-xl lg:text-lg sm:text:sm text-sky-500 font-bold tracking-wider '>{item.company}</p>
        </div>  
      </Fade>
    )
    )}
    
    </Fade>
);

export default Brand;
