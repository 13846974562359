import React from "react";
import Brand from "../parts/brand/Brand";
import Feature from "../parts/Featrue";

const featuresData = [
  {
    title: "Company Name",
    text: "株式会社データワンテクノロジー（略称 DOT）\nDataOneTech Corporation"
  },
  {
    title: "Head Office",
    text: "〒111-0053　東京都台東区浅草橋4-10-8　TFAビル7F \nJR線 浅草橋駅西口より徒歩2分 \n\n 電話：03-5829-4360 \n FAX：03-5539-4819"
  },
  {
    title: "Established",
    text: "2015(平成27)年4月20日"
  },
  {
    title: "Executive Officer",
    text: "CEO  Tatsunori Kikuchi    菊池　龍典\nCTO  Wei           Wang      王　　偉\nCFO  DaJian      Ni            倪　　大見"
  }
  ,
  {
    title: "Capital",
    text: "3,360万円"
  },
  {
    title: "Fiscal Year-End Date",
    text: "3月31日"
  },
  {
    title: "取引銀行",
    text: "三菱UFJ銀行（大伝馬支店）"
  },
  {
    title: "主要事業",
    text: "・ITシステムコンサルティング、設計、開発、保守、販売並びに導入支援事業\n・その他これらに関する一切の事業"
  },
  {
    title: "許認可ほか",
    text: "\n法人番号：8010001167409\n\n適格請求書発行事業者登録番号：T8-0100-0116-7409\n\n・情報セキュリティマネジメントシステム\n→ISO/IEC 27001:2013 / JIS Q 27001:2014\n\n・労働者派遣事業の許可\n　→許可　番号：派１３－３１３７３１\n\n・NTTデータ様アソシエイトパートナー認可"
  }
];

const FeaturePage = () => (
  <>
    <div
      className="container flex flex-col lg:flex-row xl:flex-row   justify-center "
      id="aboutus"
    >
      <div
        className="flex flex-col justify-between max-w-full mr-8 "
        id="features"
      >
        {featuresData.map((item, index) => (
          <Feature
            title={item.title}
            text={item.text}
            key={item.title + index}
          />
        ))}
      </div>
      <div className="flex flex-col justify-start  justify-item-auto mr-8 ">
        <Brand />
      </div>
    </div>
  </>
);

export default FeaturePage;
