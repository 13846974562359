/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Button from '../elements/Button';



export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/"
    >
      <p className="text-theme-blue text-xl xl:text-4xl">
        DataOne
        <span className="text-theme-purple">Tech</span>
      </p>
    </Button>
  );
}
