/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';

import Header from '../parts/Header';
import Footer from '../parts/Footer';

import Data from '../json/recruitPage.json';
import { useParams } from 'react-router-dom';
import RecruitDetail from '../parts/RecruitDetail';

export default function RecruitDetailPage ( {props} ) {
  useEffect( () => {
    window.scrollTo(0,0);
  },[]);
  
    let { recruitId } = useParams();
 
    const detailData = Data.recruit.filter((item) => item.id === `${recruitId}`);

    return (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Header {...props} />
        <RecruitDetail data={detailData.length === 1 ? [detailData[0]] : null} />
        <Footer />
      </>
    );
  
}
