import React from "react";
import Fade from 'react-reveal/Fade';

const infoSecData = [
    {
        icData: '株式会社データワンテクノロジー（以下「当社」）は、 お取引を頂いております全てのお客様及び当社で働く全ての人々の個人情報を 適切に保護することが、個人情報を取り扱う企業としての社会的責務であると認識しております。 その理念に基づき、お預かりした個人情報の取り扱い方針を以下のとおり定め、役員、社員、協働者に周知徹底し、 その保護に努めてまいります。 ',
    }
]

const infoSecOpData = [
    {
        title:'１．個人情報の取得、利用及び提供について',
        icOpData: '当社の全ての事業で取り扱う個人情報及び従業員の個人情報について、適切な取得、利用及び提供を行います。特定した利用目的の達成に必要な範囲を超えて個人情報を取り扱うことはありません、及びそのための適切な処置も講じます。利用目的を超えて個人情報の取り扱いを行う場合には、あらかじめご本人の同意を得ます。',
    },
    {
        title: '２．関係法令の遵守について',
        icOpData: '個人情報の取り扱いに際して、個人情報の保護に関する法令や条例（以下、法令）、それに基づいて定められた指針やガイドライン等を遵守し、事業活動をしてまいります。',
    },
    {
        title: '３．個人情報の安全管理について',
        icOpData: '当社は、個人情報が漏洩や改ざん、紛失、および目的外に利用されることを防止するために、関係する法令、指針やガイドライン、社内規則などの定めに従い、合理的な安全対策を講じます。 ',
    },
    {
        title: '４．個人情報の利用について',
        icOpData: '当社は、次の場合を除き、利用目的以外には個人情報を利用致しません。\n１．ご本人の同意がある場合\n２．法令に基づき開示・提供を求められた場合\n３．統計的なデータなどご本人を特定できない状態で開示・提供する場合'
    }
    ,
    {
        title: '５．個人情報保護に関する活動の継続的改善',
        icOpData: '個人情報保護に関する活動について、継続的な見直し・改善を図ります。 '
    },
    {
        title: '本「個人情報保護方針」の対象',
        icOpData: '本「個人情報保護方針」における「個人情報」「個人データ」「保有個人データ」とは、個人情報の保護に関する法律に規定するそれらを意味し、対象は当社のお客さま、取引先企業の社員、当社の社員を問いません。\n　  本「個人情報保護方針」は、各項に特別な断りがない限り当社が取得する全ての個人情報および当社が管理する全ての個人データに適用されます。'
    },
    {
        title: '附則',
        icOpData: 'この個人情報保護方針は、2017年9月8日から施行します。'
    },
    {
        title: '個人情報に関するお問い合わせ先',
        icOpData: '当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。\n　  株式会社　データワンテクノロジー　お客様情報対応窓口\n　  is-privacy@i-dataone.co.jp '
    }


]

const PrivacyPolicy = () => {
    return(
        <>
        <Fade bottom>
        <div className="w-full  flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
          <h1 className="text-xl text-theme-blue  text-justify font-bold leading-tight mb-5">
            個人情報保護ポリシー
          </h1>
      
          {infoSecData.map((item,index) => (
            <Fade bottom delay={200 * index}>
                <p className="font-light mb-5 text-justify  text-theme-blue leading-relaxed tracking-wider indent-8 xl:text-lg sm:text-xs">{item.icData}</p>
            </Fade>

          ))}
           
      
          {infoSecOpData.map((item,index) => (
            <Fade bottom delay={200 * index}>
                <h2 className="text-lg text-theme-blue text-justify font-semibold leading-tight mb-5">{item.title}</h2>
                <p className="font-light mb-5 text-justify text-theme-blue leading-relaxed tracking-wider indent-8 xl:text-lg sm:text-xs whitespace-pre-wrap">{item.icOpData}</p>
            </Fade>

          ))}
          </div>
      </Fade>
        </>
    );
}

export default PrivacyPolicy;