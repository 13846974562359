import React from "react";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";

const containerStyle = {
  height: "60vh",
  width: "80vw",
};

const center = {
  lat: 35.69814837730401,
  lng: 139.782735667967,
};


const markerLabel = {
  color: "blue",
  fontFamily: "sans-serif",
  fontSize: "20px",
  fontWeight: "bold",
  text: "株式会社データワンテクノロジー",
};

function Map() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBXhJwaEWMZALAEODvTM0ylsbc_f3Khq1Q",
  });

  return isLoaded ? (
    <div className="flex-col justify-center ">
      <h1 className=" text-sm xl:text-xl text-theme-blue font-bold">本社　〒111-0053　東京都台東区浅草橋4-10-8　TFAビル7F</h1>
      <h2 className="mb-10 text-sm xl:text-lg text-theme-blue ">TEL: 03-5829-4360 FAX: 03-5539-4819</h2>
      <div className="flex justify-center">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
      >
        <MarkerF position={center} label={markerLabel} opacity={0.5} />
      </GoogleMap>
      </div>
      </div>
  ) : (
    <></>
  );
}
export default React.memo(Map);
