/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';

import Team from '../assets/images/team1.jpeg';

export default function HeroTeam() {
  return (
    <section className="hero sm:items-center lg:items-start sm:flex-row">
      <Fade bottom>
        <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
          <h1 className="text-xl xl:text-6xl text-theme-blue font-bold leading-tight mb-5">
            Our Team
          </h1>
          <p className="font-light text-sm xl:text-xl text-theme-blue leading-relaxed text-justify">
            お客様が心から喜んでくれるチームをご提供致します。<br/><br/>・高い技術力<br/><br/>・コミュニケーション力<br/><br/>・チーム力で最大パフォーマンス提供
          </p>
        </div>
        <div className="w-full sm:w-1/2 sm:pr-12">
          <img src={Team} alt="Hero" />
        </div>
      </Fade>
    </section>
  );
}
